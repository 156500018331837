<template>
  <div class="switchbox">
    <div :class="{ action: isAction == index }" v-for="(item, index) in titlelist" :key="index"
      @click="swArea(index)">
      {{item}}
    </div>
  </div>
</template>

<script>

export default {
  props: ['titlelist'],
  components: {},
  data () {
    return {
      isAction: 0
    }
  },
  computed: {},
  watch: {},
  methods: {
    swArea (index) {
      this.isAction = index
      this.$emit('swAreabtn', index)
    }
  },
  created () {
  }
}
</script>
<style lang="less" scoped>
.switchbox {
  width: 100%;
  height: 50px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  div {
    width: 20%;
    height: 30px;
    font-size: 12px;
    margin-top: 10px;
    font-family: SourceHanSansCN;
    font-weight: bold;
    color: #a5b7bf;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
  }
  div.action {
    background: url('../../../../assets/homeimg/bt.png') no-repeat;

    background-size: 100% 100%;
    color: #fff;
  }
}
</style>
